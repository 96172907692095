<template>
  <div>
    <el-card>
      <el-form ref="searchForm" :model="searchForm" :rules="rules" inline size="mini">
        <div>
          <el-form-item
            label="进展状态:"
          >
            <el-radio-group v-model="searchForm.progressCheckStatus" size="small" prop="progressCheckStatus">
              <el-radio label="">全部</el-radio>
              <el-radio :label="0">待审核</el-radio>
              <el-radio :label="1">审核未通过</el-radio>
              <el-radio :label="2">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="求助人:">
            <el-input v-model="searchForm.helper" prop="helper" />
          </el-form-item>
          <!-- <el-form-item label="求助编号:">
            <el-input v-model="searchForm.id" prop="id" />
          </el-form-item> -->
          <el-form-item label="求助标题:">
            <el-input v-model="searchForm.title" prop="title" />
          </el-form-item>
          <el-form-item label="进展类型:">
            <el-select v-model="searchForm.progressType" placeholder="请选择进展类型">
              <el-option
                v-for="item in progressType"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictId"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
            <el-button type="primary" size="mini" @click="resetSearch('searchForm')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div class="top-bar">
        <div class="left" />
        <div class="right"><el-button type="primary" size="mini" @click="showAdd">添加进展</el-button></div>
      </div>
      <el-table
        v-loading="dataListLoading"
        :data="list"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
        size="mini"
      >
        <el-table-column type="index" width="55" label="序号" />
        <el-table-column label="求助人" prop="helper" width="80" />
        <el-table-column label="求助标题" prop="title" />
        <el-table-column label="求助编号" prop="id" />
        <el-table-column label="进展类型" prop="progressTypeName" />
        <el-table-column label="进展内容" prop="content" />
        <el-table-column label="发布时间" prop="createTime" />
        <el-table-column label="发布人" prop="publisher" />
        <el-table-column label="审核状态" prop="progressCheckStatusName" />
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="showDetail(row.id, 'look')">查看</el-button>
            <el-button type="text" size="mini" @click="showProjectDetail(row.helpNo, 'look')">查看求助</el-button>
            <el-button v-if="row.progressCheckStatus == 0" type="text" size="mini" @click="showDetail(row.id)">审核</el-button>
            <el-button v-if="row.progressCheckStatus != 0" type="text" size="mini" @click="onDelete(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        :current-page="searchForm.current"
        :page-size="searchForm.size"
        :total="total"
        layout="total, prev, pager, next, jumper"
        style="padding:20px; margin-bottom: 50px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <detail-dialog ref="detailDialog" />
    <process-detail-dialog ref="processDetailDialog" @updateList="onSearch" />
    <add-process-dialog ref="addProcessDialog" @updateList="onSearch" />
  </div>
</template>

<script>
import { getProgressPage, removeProgress } from '../../utils/api'
import ProcessDetailDialog from './components/ProcessDetailDialog.vue'
import DetailDialog from './components/DetailDialog.vue'
import AddProcessDialog from './components/AddProcessDialog.vue'

export default {
  components: {
    ProcessDetailDialog,
    DetailDialog,
    AddProcessDialog
  },
  data() {
    return {
      CheckStatus: {
        0: '审核中',
        2: '审核通过',
        1: '审核未通过'
      },
      progressType: [],
      daterange: [],
      searchForm: {
        current: 1,
        progressType: '',
        size: 8,
        progressCheckStatus: '',
        id: '',
        helper: ''
      },
      rules: {},
      list: [],
      total: 0,
      pages: 0,
      dataListLoading: false
    }
  },
  mounted() {
    this.getList()
    const { progressType } = JSON.parse(sessionStorage.getItem('dictList'))
    this.progressType = progressType
  },
  methods: {
    showAdd() {
      this.$refs.addProcessDialog.open()
    },
    async onDelete(id) {
      const confirm = await this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (confirm === 'confirm') {
        const res = await removeProgress(id)
        if (res.success) {
          this.$message.success('删除成功')
          this.onSearch()
        }
      }
    },
    showProjectDetail(id, type = '') {
      this.$refs.detailDialog.open(id, type)
    },
    showDetail(id, type = '') {
      this.$refs.processDetailDialog.open(id, type)
    },
    handleSizeChange(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchForm.current = val
      this.getList()
    },
    async getList() {
      this.dataListLoading = true
      const res = await getProgressPage({ ...this.searchForm })
      this.dataListLoading = false
      const { success, data } = res
      if (success) {
        this.list = data.records
        this.total = data.total
      }
    },
    onSearch() {
      this.searchForm.current = 1
      this.getList()
    },
    resetSearch(formName) {
      this.searchForm.progressType = ''
      this.searchForm.helper = ''
      this.searchForm.title = ''
      this.$refs[formName].resetFields()
      this.searchForm.current = 1
      this.getList()
    }
  }
}
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>
