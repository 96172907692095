<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-10-26 13:45:42
 * @LastEditors: peter
 * @LastEditTime: 2021-11-05 16:11:33
-->
<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
  >
    <div class="content">
      <el-form ref="form" :model="form" :rules="formRule" size="mini" label-width="100px">
        <el-form-item label="求助标题" prop="helpNo">
          <el-select
            v-model="form.helpNo"
            placeholder="请输入并选择求助标题"
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 300px"
          >
            <el-option
              v-for="item in projects"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="进展类型">
          <el-radio-group v-model="form.progressType">
            <el-radio :label="0">拨款进展</el-radio>
            <el-radio :label="1">其他进展</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.progressType === 0" label="拨款金额" prop="appropriationMoney">
          <el-input-number v-model="form.appropriationMoney" placeholder="请输入金额" :precision="2" :step="0.1" controls-position="right" /> 元
        </el-form-item>
        <el-form-item label="进展内容" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            :rows="4"
            placeholder="请输入进展内容"
          />
        </el-form-item>
        <el-form-item label="选择图片">
          <div class="picture-list">
            <span
              v-for="(item, index) in form.fileList"
              :key="index"
              class="pictures-item"
            >
              <el-image :src="item.url" class="avatar" :preview-src-list="form.fileList.map(file => file.url)" />
              <span class="pictures-delete" @click="handleRemovePicture(index)">
                <i class="el-icon-close" />
              </span>
            </span>
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handlePictureSuccess"
              :before-upload="beforeAvatarUpload"
              multiple
              style="display:inline-block; margin-left:5px"
            >
              <i class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">取 消</el-button>
      <el-button size="small" type="primary" @click="submit('form')">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addProgress, getCollectingList } from '../../../utils/api'
import '@/assets/style/common.less'
import httpRequest from '@/utils/httpRequest.js'
export default {
  data() {
    return {
      show: false,
      title: '发布进展',
      action: httpRequest.api('/file/upload2'),
      loading: false,
      projects: [],
      searchForm: {
        current: 1,
        size: 100,
        proStatus: 7,
        checkStatus: 2,
        title: ''
      },
      form: {
        helpNo: '',
        appropriationMoney: '',
        content: '',
        progressType: 0,
        fileList: [],
        progressCheckStatus: 2,
        autoSend: true,
      },
      formRule: {
        helpNo: [
          { required: true, message: '请输入并选择求助标题', trigger: 'blur' }
        ],
        appropriationMoney: [
          { required: true, message: '请输入拨款金额', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入进展内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.searchForm.title = query
        const res = await getCollectingList({ ...this.searchForm })
        this.loading = false
        if (res.success) {
          this.projects = res.data.records.map(item => {
            return {
              label: item.title,
              value: item.id
            }
          })
        }
      }
    },
    open() {
      this.show = true
    },
    async submit(formName) {
      const valid = await this.$refs[formName].validate().catch(err => console.log(err))
      console.log('valid', valid)
      if (valid) {
        const opts = { ...this.form }
        opts.fileList = this.form.fileList.map(item => {
          const obj = {
            fileUrl: item.fileName
          }
          return obj
        })
        const res = await addProgress(opts)
        if (res.success) {
          this.show = false
          this.$message.success('添加成功！')
          this.$emit('updateList')
        }
      }
    },
    handlePictureSuccess(res) {
      console.log('res', res)
      if (res.success) {
        this.form.fileList.push({ url: res.data.prefix, fileName: res.data.fileName })
      } else {
        this.$message.error(res.message)
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!')
      }
      return isLt2M
    },
    handleRemovePicture(index) {
      this.form.fileList.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.picture-list {
  display: flex;
}
.pictures-item {
  position: relative;
  display: block;
  border: 1px solid #f3f3;
  margin: 0 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pictures-delete {
  position: absolute;
  cursor: pointer;
  right: -5px;
  top: -10px;
  color: #fff;
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.avatars {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}
</style>
