<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
  >
    <div v-if="detail" class="dialog-wrapper">
      <div class="group">
        <div class="group-title">进展信息</div>
        <div class="info-item">
          <div class="info-item-label">求助人：</div>
          <div class="info-item-value">{{ detail.helper }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">求助标题：</div>
          <div class="info-item-value">{{ detail.title }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">求助编号：</div>
          <div class="info-item-value">{{ detail.id }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">进展类型：</div>
          <div class="info-item-value">{{ detail.progressTypeName || '暂无' }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">拨款金额：</div>
          <div class="info-item-value">{{ detail.appropriationMoney || 0 }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">进展内容：</div>
          <div class="info-item-value">{{ detail.content }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">进展图片：</div>
          <div class="info-item-value">
            <el-image
              v-for="item in detail.fileList"
              :key="item.id"
              class="img-list-item"
              style="width: 70px; height: 70px;margin: 3px;"
              :src="item.fileUrl"
              :preview-src-list="detail.fileList.map(item => item.fileUrl)"
              :z-index="9999"
            />
          </div>
        </div>
      </div>
      <div class="examine-form">
        <div v-if="pageType === 'look'" class="group">
          <div class="group-title">审核信息</div>
          <div class="info-item">
            <div class="info-item-label">审核状态：</div>
            <div class="info-item-value">{{ detail.progressCheckStatusName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核人：</div>
            <div class="info-item-value">{{ detail.checkUser }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核时间：</div>
            <div class="info-item-value">{{ detail.checkDate }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核意见：</div>
            <div class="info-item-value">{{ detail.checkOpinion }}</div>
          </div>
        </div>
        <div v-else class="group">
          <div class="group-title">审核</div>
          <el-form :model="form" label-width="100px">
            <el-form-item label="审核：">
              <el-radio-group v-model="form.progressCheckStatus">
                <el-radio :label="2">通过</el-radio>
                <el-radio :label="1">未通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核意见：">
              <el-input v-model="form.checkOpinion" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">取 消</el-button>
      <el-button v-if="pageType !== 'look'" type="primary" size="small" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProgressDetail, checkProjectProgress } from '../../../utils/api'
import '@/assets/style/common.less'
export default {
  data() {
    return {
      show: false,
      title: '查看',
      id: '',
      detail: null,
      form: {
        progressCheckStatus: 2,
        checkOpinion: ''
      },
      pageType: ''
    }
  },
  methods: {
    open(id, type = '') {
      this.show = true
      this.id = id
      this.getDetail()
      if (type === 'look') {
        this.pageType = 'look'
        this.title = '查看'
      } else {
        this.pageType = ''
        this.title = '审核'
      }
    },
    async getDetail() {
      const res = await getProgressDetail(this.id)
      const { success, data } = res
      if (success) {
        this.detail = data
      }
    },
    async submit() {
      const opts = {
        id: this.detail.id,
        ...this.form
      }
      const res = await checkProjectProgress(opts)
      const { success } = res
      if (success) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('updateList')
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>
<style lang="less">
  .examine-form {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
  }
</style>
